<template>
  <!-- v-if="networkData.length" -->
  <b-card
    no-body
  >
    <div class="card-header">
      <!-- Title -->
      <b-card-title class="mt-1 mb-1" style="margin: 0 auto"> <h3> <strong> 사료급이기 기록 </strong> </h3> </b-card-title>
    </div>

    <!-- <div class="text-left ml-1">
      ※ <strong>{{ sortBy === 'value' ? '급이량' : '일자'}}</strong> 기준으로 <strong>{{ sortDesc ? '내림차순' : '오름차순' }}</strong> 정렬
    </div> -->

    <b-row>
      <b-col cols="12">
        <b-table hover 
          style="width: -webkit-fill-available"
          :items="networkData"
          :fields="fields"
          :tbody-tr-class="rowColor"
          :per-page="perPage"
          :current-page="currentPage"
          show-empty
          empty-text="데이터가 없습니다"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          head-variant="dark"
          :bordered="true"
          @row-clicked="updateBtnValue"
        />
      </b-col>      
      <b-col
        cols="12"
        class="p-2"
      >
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          :align="`center`"
          size="lg"
          class="my-0"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BCardTitle, BTable, BPagination, BRow, BCol, BFormInput, BInputGroup
} from 'bootstrap-vue'
//import axiosIns from '@/libs/axios'
import axios from 'axios'
import { getUserData } from '@/auth/utils'
import { getDateString } from '@core/utils/utils'
import store from '@/store'

export default {
  components: {
    BCard,
    BCardTitle,
    BTable,
    BPagination,
    BRow,
    BCol,
    BFormInput,
    BInputGroup
  },
  /* props: {
    farmId: {
      type: String,
      default: '',
    },
  }, */
  data() {
    return {
      userInfo: null,
      pageAuth: false,
      targetBtns: ['feeder', 'feeder_1button'],
      sortBy: 'timestamp',
      sortDesc: true,
      perPage: 10,
      currentPage: 1,
      totalRows: 0,
      networkData: [],
      fields: [
        {
          key: 'farm',
          label: '축사',
          sortable: false,
          tdClass: 'td',
          formatter: value => value,
        },
        {
          key: 'name',
          label: '이름',
          sortable: false,
          tdClass: 'td',
          formatter: value => value,
        },
        {
          key: 'value',
          label: '급이량(kg)',
          sortable: false,
          tdClass: 'td',
          formatter: value => value+'kg',
        },
        {
          key: 'timestamp',
          label: '일자',
          sortable: true,
          tdClass: 'td',
          formatter: value => value,
        },
        /* {
          key: 'command',
          label: '명령어',
          sortable: true,
          formatter(value) {
            if (value === 'create_button') {
              return '버튼 생성'
            }
            if (value === 'update_button') {
              return '버튼 수정'
            }
            if (value === 'delete_button') {
              return '버튼 삭제'
            }
            if (value === 'create_device') {
              return '장치 생성'
            }
            if (value === 'update_device') {
              return '장치 수정'
            }
            if (value === 'delete_device') {
              return '장치 삭제'
            }
            if (value === 'set_relay') {
              return '릴레이 설정'
            }
            if (value === 'create_sensor') {
              return '센서 생성'
            }
            if (value === 'update_sensor') {
              return '센서 수정'
            }
            if (value === 'delete_sensor') {
              return '센서 삭제'
            }
            return ''
          },
        },
        { key: 'status', label: '상태', sortable: true }, */
        /* {
          key: 'createdAt',
          label: '일자',
          sortable: true,
          formatter: value => getDateString(value),
        }, */        
      ],
    }
  },
  created() {
    this.getInfo()
  },
  methods: {
    updateBtnValue(data, index) {
      let text
      let farm = data.farm
      let name = data.name
      let value = data.value
      let date = data.timestamp
      this.$bvModal.msgBoxConfirm(<span> 
      <p>축사: {farm}</p>
      <p>장비 이름: {name}</p>
      <p>사료량: {value}KG</p>
      <p>동작 일시: {date}</p>
      <hr></hr>
      <b>수정 사료량을 입력해주세요.</b>
      <b-input-group append="kg" class="mt-1">
        <b-form-input vModel={text} vOn:keyup_enter={ok} type="number" style="width: 20%;" /> </b-input-group>
        </span> , {
        title: '사료량 수정',
        okTitle: '확인',
        cancelTitle: '취소',
        centered: true,
        }).then((ok) => {
          if(ok) {
            if(text === undefined 
            || text === null
            || text === ''
            || text.length > 4) {
              alert('사료량을 다시 확인해주세요')
            } else {  
              const queryBody = {
                value: text,
                buttonId: data.buttonId
              }
              store.dispatch('button/updateValueOfLog', {
                logIdx: data.id,
                queryBody
              })
              .then(res => {
                console.log('UpdateValueOfLog Return msg', res)
                let target = this.networkData.find(el => el.id === data.id)
                target.value = res.data.data
              })
              .catch(err => {
                alert('서버가 응답하지 않습니다.')
                console.log(err)
              })
              /* const param = {
                command: newVal,
                commandValue: this.sliderValue,
                feedValue: this.feedValue,
              }
              store.dispatch('button/command', { id: this.buttonItem._id, queryBody: param })
              .then(res => {
                if(res.status === 200){
                  this.updateData()
                }         
              }).catch(error => {
                console.log(error)
              }) */
            }
            
          }
      })
      
    },
    async getBtnLogs (data) {
      
      const logs = await store.dispatch('button/getBtnLogList', {buttonId: data._id})
      //await axios.get(`http://ewha-api.iptime.org:3028/api/button/log/${id}`)
      if(logs.data.length > 0) {
        //this.networkData = logs.filter(el => el.result === 'true')
        for(let log of logs.data) {
          if(log.result === 'true' && log.command === 'work') {
            this.networkData.push({
              id: log.id,
              buttonId: data._id,
              farm: data.farmId.name,
              name: data.name,
              value: log.value || 60,
              timestamp: log.date
            })
          }
          
        }
      }
      

      //if(logs.data.length > 0)
    },
    async getInfo() {


      this.userInfo = getUserData()
      const btnsInfo = await store.dispatch('button/fetchButtons', { userId: this.userInfo.id })
      if(btnsInfo.data.length > 0) {
        const targets = btnsInfo.data.filter(btn => this.targetBtns.findIndex(el => el === btn.type) > -1)
        for(let target of targets) {
          this.getBtnLogs(target)
        }
        if(targets.length >= 1) this.pageAuth = true

      } else {
        this.$toast('기록되는 장비가 존재하지 않습니다.', {
          position: 'bottom-right',
          timeout: 1500,
          icon: true,
          closeOnClick: true,
          type: 'error',
        })
        this.$router.push({ name: 'remote-control' })
      }
      return this.userInfo
    },
    rowColor(item, type) {
      if (!item || type !== 'row') {
        return
      }
      if (item.status === 'failure') {
        // eslint-disable-next-line consistent-return
        return 'table-danger'
      }
    },
  },
}
</script>

<style>
.td {
  min-width: 10px;
  overflow: hidden;
}
</style>
